import pkg from '../package.json';

/**
 * @deprecated
 * The option of using window.location to determine the baseUrl
 * will be deprecated once decoupling is completed and we do not
 * include the dashboard as a git submodule to the backend
 */
const { protocol: localProtocol, port: hostPort, hostname } = window.location;
const port = hostPort !== '80' ? `:${hostPort}` : '';

const server = {
  protocol: process.env.REACT_APP_DASHBOARD_PROTOCOL ?? localProtocol.replace(/:$/, ''),
  domain: process.env.REACT_APP_DASHBOARD_DOMAIN ?? hostname + port,
  apiVersion: 19,
};

const { protocol, domain, apiVersion } = server;

const baseUrl = `${protocol}://${domain}/v${apiVersion}`;

const config = {
  version: pkg.version,
  server,
  baseUrl,
  apiUrl: `${baseUrl}/admin`,
  apiUrlDrf: `${baseUrl}/drf/admin`,
  MAX_TIME_INACTIVE: 30 * 60, // 30 minutes * 60 seconds
  SECONDS_TIMEOUT: 60,
};

export default config;
